<script setup lang="ts">
import type { FieldValues, Article } from '@/types';

import { computed } from 'vue';
import ArticleImage from '@/components/ArticleImage.vue';

const props = defineProps({
  withImage: {
    type: Boolean,
    default: true,
  },
  modelValue: {
    type: Object as () => FieldValues,
    required: true,
  },
});
const article = computed<Article>(() => {
  return props.modelValue.article || ({} as Article);
});
const image = computed(() => {
  const filepath = article.value.Images?.primary.filepath;
  return !filepath ? null : import.meta.env.VITE_IMAGE_BASE_URL.replace('{size}', '167x') + filepath;
});
</script>

<template>
  <div
    v-if="modelValue"
    :class="{
      'is-flex': withImage && image,
    }"
    style="grid-column: span 4 / auto; flex-direction: row"
  >
    <div class="relative is-flex has-text-centered is-image item-long-description mr-4" style="justify-content: center">
      <span v-if="withImage && image" class="is-relative small-overlay">
        <ArticleImage :article="article">
          <img width="50" height="50" :src="image" alt="" class="product-image attachment-full size-full" style="min-width: 50px; min-height: 50px" />
        </ArticleImage>
      </span>
    </div>
    <div class="item-long-description">
      <span class="is-block article-title">
        {{ article.ItemLongDescription }}
      </span>
      <span class="is-size-7 is-block artnr">Art.nr {{ article.ArtNr }}</span>
    </div>
  </div>
</template>
<style lang="sass">
@import "@/assets/variables.sass"

.item-long-description
  .article-title
    color: $black
</style>
