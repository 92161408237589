<script setup lang="ts">
import type { Section, SectionValues } from '@/types'

import { ref } from 'vue'

import IconChevronDown from './icons/IconChevronDown.vue'

/*
Example

columns = [
  {
    key: string, -- Should match row key
    label: string, -- Label to display
  },
  ...
]

rows = [
  {
    key: string, -- Should match column key
    value: string, -- usually a v-model value (depends on the component)
    component: {
      props: {
        [key: string]: any, -- component props
      },
      component: VueComponent -- Component to use
    }
  },
  ...
]

*/

const expanded = ref<{ [key: string]: boolean }>({})
const toggle = (key: string) => {
  expanded.value[key] = !expanded.value[key]
}

defineProps<{
  modelValue: SectionValues
  sections: Section[]
  expandable?: boolean
}>()
</script>

<template>
  <div>
    <template v-for="section in sections" :key="section.section">
      <div class="section full-width">
        <div v-if="section.label" class="section-title">
          <h1 class="title">{{ section.label }}</h1>
        </div>
        <table class="table is-fullwidth" :class="section.class">
          <thead>
            <tr>
              <th
                v-for="(column, index) in section.columns"
                class="is-uppercase is-table-title"
                :class="column.class"
                :key="column.key + index"
                @click="toggle(section.section)"
              >
                <component
                  :is="column.component?.component || 'div'"
                  v-bind="column.component?.props"
                  class="is-flex is-justify-content-space-between-mobile"
                  :class="column.class"
                >
                  <span>{{ column.label }}</span>
                  <button v-if="expandable && index === 0" class="link expand-button nowrap">
                    {{ expanded[section.section] ? $t('generic.hide') : $t('generic.show') }}
                    <IconChevronDown :size="22" :active="expanded[section.section]" />
                  </button>
                </component>
              </th>
            </tr>
          </thead>
          <transition-group
            name="list-complete"
            tag="tbody"
            :class="{
              'is-hidden': expandable && !expanded[section.section]
            }"
          >
            <tr v-for="(row, index) in modelValue[section.section]" :key="index">
              <td
                v-for="(column, index) in section.columns"
                :key="column.key + index"
                :class="column.class"
              >
                <component
                  v-if="column.key in row"
                  :is="section.rows[column.key].component.component"
                  v-bind="section.rows[column.key].component.props"
                  :label="column.label"
                  v-model="row[column.key]"
                />
              </td>
            </tr>
          </transition-group>
        </table>
      </div>
    </template>
  </div>
</template>

<style lang="sass" scoped>
@import "@/assets/variables.sass"
@import "bulma/sass/utilities/mixins.sass"

thead
  &:hover
    background: $gray-1
  th
    cursor: pointer

  td:first-of-type
    width: 70%
  .amount
    width: 5.3rem
  .unit
    width: 16%
  .is-vbottom
    vertical-align: bottom

.expand-button
  display: flex
  align-items: center
  font-size: 0.938rem
  margin-left: 0.5rem
  font-weight: 400
  text-transform: capitalize
  letter-spacing: 0.02rem
  svg
    margin-left: 0.25rem

.is-invisible
  tr
    display: block !important
    height: 0 !important
    padding: 0 !important
    margin: 0 !important
    border: none !important

.list-complete-enter-active,
.list-complete-leave-active
  pointer-events: none
  transition: all 0.5s ease

.list-complete-enter-from,
.list-complete-leave-to
  opacity: 0
  transform: translateX(30px)

.control
  text-align: left

@include tablet
  .control :deep() .label
    display: none


@include mobile
  .is-justify-content-space-between-mobile
    justify-content: space-between

  .section
    padding-top: 1rem

  .step-summary .substeps
    tbody tr td
      grid-column: span 2 / auto
      width: 100%
      padding-bottom: 0
      padding-top: 0

  .substeps
    .table
      table-layout: fixed

      th:not(:first-of-type)
        display: none

      tbody
        tr
          display: grid
          grid-template-columns: repeat(2,1fr)
          border-top: 1px solid #e2e3e4
          padding: 0.5rem 0

          &:first-of-type
            border-top: none

          td
            border: none
            &:first-of-type
              grid-column: span 2 / auto
              width: 100% !important
</style>
