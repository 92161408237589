<script setup lang="ts">
import { TerrainType, WindZone, SnowZone } from '@/options.d'
import { optionMap } from '@/helpers'
import { ref } from 'vue'

import { project, enumToOptions } from '@/helpers'

import GenericModal from '@/components/modals/GenericModal.vue'
import SnowzoneModal from '@/components/modals/SnowzoneModal.vue'
import WindzoneModal from '@/components/modals/WindzoneModal.vue'
import RadioSelector from '@/components/RadioSelector.vue'

import TerrainTypes from '@/assets/images/terrain_types.png'

const terrain_types = enumToOptions(TerrainType)
const wind_zones = enumToOptions(WindZone)
const snow_zones = enumToOptions(SnowZone, {
  labels: optionMap.snow_zone
})

const modal = ref({
  terrain_types: false,
  snow_zones: false,
  wind_zones: false
})
</script>

<template>
  <div class="section">
    <GenericModal v-if="modal.terrain_types" @close="modal.terrain_types = false">
      <div class="flex flex-col items-center" style="background: white; padding: 1.5rem 2rem">
        <img :src="TerrainTypes" alt="Terräng typ information" />
      </div>
    </GenericModal>
    <SnowzoneModal
      v-if="modal.snow_zones"
      :width="600"
      :height="600"
      @close="modal.snow_zones = false"
    />
    <WindzoneModal
      v-if="modal.wind_zones"
      :width="600"
      :height="600"
      @close="modal.wind_zones = false"
    />
    <h2 class="benders-small-title is-uppercase">{{ $t('conditions.area') }}</h2>
    <div class="terrain-type">
      <h2 class="benders-small-title">
        {{ $t('conditions.terrain') }}
        <font-awesome-icon
          :icon="['far', 'circle-info']"
          class="info"
          @click="modal.terrain_types = true"
        />
      </h2>
      <RadioSelector
        :items="terrain_types"
        group="terrain_type"
        v-model="project.selections.terrain_type"
      />
    </div>
    <div class="wind-zone">
      <h2 class="benders-small-title">{{ $t('conditions.wind_zone') }}</h2>
      <RadioSelector :items="wind_zones" group="wind_zone" v-model="project.selections.wind_zone" />
      <a class="is-size-6" @click="modal.wind_zones = true">
        {{ $t('conditions.wind_zone_info') }}
      </a>
    </div>
    <div class="snow-zone">
      <h2 class="benders-small-title">{{ $t('conditions.snow_zone') }}</h2>
      <RadioSelector :items="snow_zones" group="snow_zone" v-model="project.selections.snow_zone" />
      <a class="is-size-6" @click="modal.snow_zones = true">
        {{ $t('conditions.snow_zone_info') }}
      </a>
    </div>
  </div>
</template>
