<script setup lang="ts">
import type { Article } from '@/types'

import { computed } from 'vue'

const nonRepresentativeImage = computed(() => {
  if (!prop.article) return false
  const primary = prop.article.Images?.primary

  return primary.type !== 'Yta' && primary.source !== prop.article.C4Id
})

const prop = defineProps<{
  article?: Article
}>()
</script>

<template>
  <slot></slot>
  <div v-if="nonRepresentativeImage" class="overlay">
    <div class="text">
      {{ $t('overlay.nonrepresentativeimage') }}
    </div>
  </div>
</template>

<style lang="sass" scoped>
.overlay
  position: absolute
  bottom: 10px
  top: 3px
  left: 3px
  right: 3px
  background-color: rgba(255, 255, 255, 0.5019607843)
  overflow: hidden
  > .text
    color: white
    font-size: 1rem
    position: absolute
    top: 20%
    margin: 0 1rem
    /* left: 50% */
    /* transform: translate(-50%, -50%) */
    text-align: center
    text-shadow: 2px 2px 2px rgba(150, 150, 150, 1)
.small-overlay
  .overlay
    top: 0
    left: 0
    right: 0
    bottom: 0
    > .text
      font-size: 0.4rem
      top: 0
      margin: 0
</style>
