<script setup lang="ts">
import type {
  Section,
  SectionValues,
  SectionValueItem,
  SectionColumn,
  SectionRow,
  FieldValues,
  Article
} from '@/types'

import { watch, computed, ref, markRaw } from 'vue'
import { computedAsync } from '@vueuse/core'

// import { id, data, calculated } from '@/helpers'
import { selections, calculated, projectName, setWeight, computedArticles } from '@/helpers'
// import { useProjectStore } from '@/store/project'
import { useArticleStore } from '@/store/article'
import { useFrameStore } from '@/store/frame'
import { weightFormatter } from '@/helpers'
import { useI18n } from 'vue-i18n'
import { generatePDF } from '@/api/pdf'
// import { deleteList } from '@/api/api'

import ProductList from '@/components/ProductList.vue'
import PrintingTable from '@/components/PrintingTable.vue'
import ArticleField from '@/components/productlist/ArticleField.vue'
import AmountField from '@/components/productlist/AmountField.vue'
import RoofTableHeader from '@/components/productlist/RoofTableHeader.vue'
import SummarySelections from '@/components/SummarySelections.vue'

const { t } = useI18n({ useScope: 'global' })
const { getArticles } = useArticleStore()
const { userCanBuy, defaultWarehouse } = useFrameStore()

const palletMap = {
  '099900': 'Bender Pall',
  '099902': 'Pall (ej returnerbar)',
  '099903': 'Pall (Lasken)',
  '099945': 'Bender Reglar (BRS)',
  '099946': 'Europall',
  '099990': 'Färgcontainer',
  '099920': 'Palldelning'
} as { [key: string]: string }

const weightMap = {
  '099900': '20',
  '099902': '20',
  '099903': '20',
  '099945': '5',
  '099946': '20',
  '099990': '20',
  '099920': '20'
} as { [key: string]: string }

const articles = computedAsync(async () => {
  const artnrs = computedArticles.value.map((a) => a.artnr)
  return await getArticles(artnrs)
})

const values = computed<SectionValues>(
  () => {
    if (!articles.value) return {}

    let totalWeight = 0
    return computedArticles.value.reduce((acc, cur) => {
      // const cat = categoryMap[cur.category]?.toLowerCase() as string
      const cat = 'products'
      if (cat) {
        acc[cat] = acc[cat] || []
        const article =
          articles.value[cur.artnr] ||
          ({
            ItemLongDescription: palletMap[cur.artnr],
            ArtNr: cur.artnr,
            ItemGrossWeight: weightMap[cur.artnr] || 0
          } as Article)
        const weight = parseFloat(article.ItemGrossWeight) || 0
        // Set article data, and data for "total" column
        const v = {
          // [cat]: {
          //   article: article,
          //   total: cur.origins[cat],
          //   weight: weight * cur.origins[cat]
          // },
          [cat]: {
            article: article,
            total: cur.total,
            weight: weight * cur.total
          },
          total: {
            article: article,
            total: cur.total,
            weight: weight * cur.total
          }
        } as { [key: string]: FieldValues }

        // // Set data for each roof column
        // for (const key in cur.origins) {
        //   v[key] = {
        //     article: article,
        //     total: cur.origins[key],
        //     weight: weight * cur.origins[key]
        //   }
        // }

        totalWeight += v.total.weight
        setWeight(totalWeight)
        acc[cat].push(v)
      }
      return acc
    }, {} as SectionValues)
  },
  {} as SectionValues // initial state
)

const sections = computed<Section[]>(() => {
  const buildColumns = (section: string) => {
    const columns = [] as SectionColumn[]
    // Add in each roof as a column
    // columns.push(...selections.value.roofs.reduce((acc, cur) => {
    //   const label = cur.name.toLowerCase()
    //   if (selections.value.roofs.length > 1) {
    //     acc.push({
    //       key: cur.id,
    //       label: label.charAt(0).toUpperCase() + label.slice(1),
    //       class: 'is-justify-content-flex-end',
    //       component: {
    //         component: markRaw(RoofTableHeader),
    //         props: {
    //           id: cur.id
    //         }
    //       }
    //     })
    //   }
    //   return acc
    // }, [] as SectionColumn[]))

    columns.push({
      key: 'total',
      // label: 'Tot.antal',
      label: t('summary.amount'),
      class: 'is-justify-content-flex-end'
    })
    return columns
  }

  return ['products'].reduce((acc, section) => {
    section = section.toLowerCase()
    let label = section.charAt(0).toUpperCase() + section.slice(1)
    switch (section) {
      case 'products':
        label = t('productlist.product')
        break
    }

    // Filter out sections that have no values
    if (!(section in values.value)) return acc

    acc.push({
      section: section,
      class: 'has-text-right',
      columns: [
        {
          key: section,
          label: label,
          class: 'has-text-left is-vbottom'
        },
        ...buildColumns(section)
      ],
      rows: {
        [section]: {
          component: {
            component: markRaw(ArticleField)
          }
        },
        // Add in each roof as a row
        ...selections.value.roofs.reduce((acc, cur) => {
          // let key = cur.name.toLowerCase();
          acc[cur.id] = {
            component: {
              component: markRaw(AmountField)
            }
          }
          return acc
        }, {} as SectionRow),
        total: {
          component: {
            component: markRaw(AmountField)
          }
        }
      }
    })
    return acc
  }, [] as Section[])
})

const containsConfigurableProducts = computed(() => {
  for (const key in articles.value) {
    if (articles.value[key].configurationCode === 1) return true
  }
  return false
})

const printarea = ref<{ html: () => string }>()

// // DEBUGGING
// const testframe = ref<HTMLIFrameElement>();
// const oldHtml = ref<string>('');
// setInterval(() => {
//   if (!testframe.value?.contentWindow) return;
//   const html = printarea.value?.html();
//   if (!html || html === oldHtml.value) return;
//   oldHtml.value = html;
//   testframe.value.contentWindow.document.body.innerHTML = html || '';
// }, 1000);
// // END DEBUGGING

const getPDF = async (type: string) => {
  const html = printarea.value?.html()

  if (!html) {
    console.error('Failed to generate PDF', html)
    return
  }

  const pdf = await generatePDF(html, `${projectName.value}.pdf`)

  if (!pdf) {
    console.error('Failed to generate PDF', pdf)
  }

  let iframe
  const url = window.URL.createObjectURL(new Blob([pdf], { type: 'application/pdf' }))

  switch (type) {
    case 'PDF':
      console.debug('Opening PDF in new window', url)
      window.open(url, '_blank')
      break
    default:
      iframe = document.createElement('IFRAME') as HTMLIFrameElement
      iframe.className = 'ben-iframe-print'
      iframe.style.display = 'none'
      iframe.src = url
      iframe.onload = function () {
        interface BendersWindow extends Window {
          __container__: BendersHTMLIFrameElement
        }
        interface BendersHTMLIFrameElement extends HTMLIFrameElement {
          contentWindow: BendersWindow
        }

        const self = this as BendersHTMLIFrameElement
        if (!self.contentWindow) {
          console.error('Failed to get contentWindow for printing')
          return
        }
        function closePrint(this: unknown) {
          const self = this as BendersWindow
          document.body.removeChild(self.__container__)
        }

        self.contentWindow.__container__ = self
        self.contentWindow.onbeforeunload = closePrint
        self.contentWindow.onafterprint = closePrint
        self.contentWindow.focus() // Required for IE
        self.contentWindow.print()
      }

      document.body.appendChild(iframe)
  }
}

const show = computed(() => {
  return {
    summary: Object.keys(values.value).length > 0,
    addToCart: !!window.parent && userCanBuy && false //BENS-30: Disabled for now
  }
})

const showSelections = ref(false)

const action = {
  reset: () => {
    // TODO: Properly reset the project
    window.location.href = '/'
  },
  // delete: () => {
  //   // ask if we want to delete
  //   if (!confirm(t('project.delete.confirm', { id: id.value }))) {
  //     return
  //   }

  //   deleteList(id.value).then(() => {
  //     router.push({ name: 'home' })
  //   })
  // },
  addToCart: () => {
    if (!show.value.addToCart) {
      return
    }
    window.parent.postMessage(
      {
        type: 'bendersAddToCart',
        items: computedArticles.value
          .filter((row) => row.total > 0 && !(row.artnr in palletMap))
          // .filter((row) => articles.value[row.artnr]) // Just for testing, probably shouldn't filter out the articles that do not exist
          .map((row) => {
            const article = articles.value[row.artnr]
            if (!article) {
              console.error('Could not add to cart, Article not found', row.artnr)
              return
            }
            return {
              artNumber: row.artnr,
              qty: row.total,
              unit: article.basicUnitOfMeasure,
              // color: row.color || null,
              artId: article.C4Id
            }
          }),
        // mainArticle: this.sortedTableRows.find(row => row?.values?.type == 'mur')?.artNbr,
        preferredWarehouse: defaultWarehouse,
      },
      '*'
    )
  },
  pdf: {
    save: () => {
      getPDF('PDF')
    },
    print: () => {
      getPDF('PRINT')
    }
  }
}
</script>

<template>
  <div>
    <div class="step-summary">
      <div class="summary-selections mt-4">
        <!-- Button to show/hide selections -->
        <div class="selections-toggle">
          <button
            class="button is-uppercase has-text-weight-bold pl-4 pr-4"
            @click="showSelections = !showSelections"
          >
            {{
              showSelections
                ? t('summary.buttons.hide_selections')
                : t('summary.buttons.show_selections')
            }}
          </button>
        </div>
        <SummarySelections v-if="showSelections" />
      </div>
      <div class="substeps">
        <template v-if="show.summary">
          <ProductList :sections="sections" v-model="values" />
          <div class="weight-wrapper has-text-left">
            <span class="label">Vikt</span>
            <span class="weight">{{ weightFormatter(calculated.weight) }} kg </span>
          </div>
        </template>
      </div>
      <div class="summary-actions">
        <div class="left"></div>
        <div class="right">
          <button
            class="button is-uppercase has-text-weight-bold pl-4 pr-4 mt-4"
            @click="action.pdf.save"
          >
            {{ t('summary.buttons.save') }}
          </button>
          <button
            class="button is-uppercase has-text-weight-bold pl-4 pr-4 mt-4"
            @click="action.pdf.print"
          >
            {{ t('summary.buttons.print') }}
          </button>
          <div
            v-if="show.addToCart"
            class="button-wrapper"
            data-balloon-blunt
            data-balloon-pos="up"
            data-balloon-length="large"
            :aria-label="
              containsConfigurableProducts ? t('summary.buttons.addToCartConfigurable') : undefined
            "
          >
            <button
              v-if="show.addToCart"
              class="button is-uppercase has-text-weight-bold pl-4 pr-4 mt-4"
              :class="{
                disabled: containsConfigurableProducts
              }"
              @click="!containsConfigurableProducts ? action.addToCart() : undefined"
            >
              {{ t('summary.buttons.addToCart') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <PrintingTable
      class="is-hidden"
      ref="printarea"
      :articles="values"
      :sections="sections"
      :sectionValues="values"
    />
    <!-- <iframe style="width: 100%; height: 400px" ref="testframe"></iframe> -->
  </div>
</template>

<style lang="sass" scoped>
@import "@/assets/variables.sass"
@import "bulma/sass/utilities/mixins.sass"

.roof-table-header-container
    display: flex
    flex-direction: column
    @media screen and (min-width: 768px)
        flex-direction: row
        justify-content: flex-end

.substeps
  // padding-top: 3.75rem

  .weight-wrapper
    margin-top: 0.75rem
    padding-left: 0.75rem
    .label
      margin-bottom: 0
    .weight
      font-size: 0.85rem

.substeps :deep(.section)
  padding-bottom: 0
  padding-top: 1rem

.substeps :deep(.section:not(:first-of-type))
  table
    tr
      border-top: 1px solid #e2e3e4
      &:first-of-type
        border-top-width: 0

    th:not(:first-of-type)
      opacity: 0
      pointer-events: none

    thead tr
      border-top: 0

.summary-actions
  display: flex
  flex-direction: column-reverse
  margin-top: 2rem
  @media screen and (min-width:768px)
    margin-top: 6rem
    flex-direction: row
    align-items: center
    justify-content: space-between


  .left
    display: flex
    align-items: center
    .button
      margin-right: 0.5rem

  .right
    display: flex
    flex-direction: column
    align-items: flex-start
    @media screen and (min-width:768px)
        flex-direction: row
        align-items: center
        > * + *
            margin-left: 0.5rem

.roofs
  border-bottom: 2px solid #ebebeb

h2.title
  font-size: 0.85rem
.step-summary .substeps :deep(table th:not(:first-child))
  width: 10%

.roof-name
  font-size: 0.85rem
  font-weight: 600
.disabled
  pointer-events: none
  opacity: 0.5
</style>
