<script setup lang="ts">
import type { RadioOption } from '@/project'

import { computedAsync } from '@vueuse/core'

import IconOptionCheck from '@/components/icons/IconOptionCheck.vue'

const blankImage = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
const cItems = computedAsync<RadioOption[]>(async () => {
  const items = []
  for (const item of prop.items) {
    if (item.image) {
      const image = await item.image
      items.push({
        ...item,
        image
      })
    } else {
      items.push({
        ...item,
        image: blankImage
      })
    }
  }
  return items
}, [])

const prop = defineProps<{
  items: RadioOption[]
  modelValue: unknown
}>()

const emit = defineEmits<{
  (event: 'update:modelValue', value: unknown): void
}>()
</script>

<template>
  <div class="selector-wrapper-show column is-flex is-justify-content-center">
    <div
      v-for="item in cItems"
      :key="`${item.artnr}-${item.value}`"
      @click="emit('update:modelValue', item.value)"
      :class="['options', { active: modelValue === item.value }]"
    >
      <div class="image-wrapper">
        <IconOptionCheck v-if="modelValue === item.value" class="checkmark" />
        <slot name="image" :item="item" class="image"></slot>
      </div>
      <slot name="label" :item="item" class="label">{{ item.label }}</slot>
    </div>
  </div>
</template>

<style scoped lang="sass">
@import "@/assets/variables.sass"

.options

  .image-wrapper
    position: relative
    width: 100px
    height: 100px
    margin: 0.25rem
    padding: 0.5rem
    border: 3px solid $gray-4
    cursor: pointer

    .checkmark
      opacity: 1

    :deep() img
      height: 100% !important

  &.active .image-wrapper,
  &:hover .image-wrapper
    border-color: $benders-red
</style>
