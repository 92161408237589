<script setup lang="ts">
import type { SectionPanel, SectionObstacle } from '@/project.d'

import { ref, watch, nextTick } from 'vue'
import { useProjectStore } from '@/store/project'
import { activeRoof, formatNumber, roofIndex, roofCount, minTakfall, minTakbredd } from '@/helpers'

import PanelSection from '@/components/PanelSection.vue'
import draggable from 'vuedraggable'

const { setRoofIndex, deleteRoof } = useProjectStore()

const gridWrapper = ref<HTMLElement>()
const gridContainer = ref<HTMLElement>()
const left = ref<HTMLElement>()
const right = ref<HTMLElement>()

const roofTableScale = ref(1)

const removeRoof = () => {
  const index = roofIndex.value
  // Remove
  setRoofIndex(Math.max(0, index - 1))
  nextTick(() => {
    deleteRoof(index)
  })
}

const removeRow = (index: number) => {
  roofTableScale.value = 1 // Reset scale (Causes issues with re-scaling otherwise)
  const rows = activeRoof.value.rows
  if (rows.length === 1) {
    return
  }
  rows.splice(index, 1)
}

const activePanel = ref({} as SectionPanel | SectionObstacle | null)

watch(
  () => [roofIndex.value, activeRoof.value.rows],
  () => {
    nextTick(() => {
      const width = {
        gridWrapper: gridWrapper?.value?.offsetWidth,
        gridContainer: gridContainer?.value?.offsetWidth
      }
      if (!width.gridWrapper || !width.gridContainer) {
        roofTableScale.value = 1
        return
      }

      const padding = 24
      // container - padding - left & right width(We just assume they are 10% of the container as they are flex)
      width.gridContainer = width.gridContainer - padding * 2 - width.gridContainer * 0.1
      // calculate original width, before we scaled it
      width.gridWrapper = width.gridWrapper / roofTableScale.value
      if (width.gridWrapper < width.gridContainer) {
        roofTableScale.value = 1
        return
      }

      roofTableScale.value = Math.round((width.gridContainer / width.gridWrapper) * 100) / 100
    })
  },
  { immediate: true, deep: true }
)
</script>

<template>
  <div class="placement">
    <div class="grid-container" ref="gridContainer">
      <div class="left" ref="left">
        <span class="text">{{
          $t('placement.roof_slope', { amount: formatNumber(minTakfall() / 1000) })
        }}</span>
        <span class="line">
          <span></span>
          <span></span>
        </span>
      </div>
      <div class="top">
        <span class="text">{{
          $t('placement.roof_width', { amount: formatNumber(minTakbredd() / 1000) })
        }}</span>
        <span class="line">
          <span></span>
          <span></span>
        </span>
      </div>
      <div class="grid-wrapper" ref="gridWrapper">
        <div class="grid-content">
          <div
            v-for="(row, rowIndex) in activeRoof.rows"
            :key="rowIndex"
            class="block-wrapper is-relative is-flex has-flex-direction-row is-flex-wrap-nowrap"
            :style="{
              minHeight: `${50 * roofTableScale}px`
            }"
          >
            <draggable
              v-model="row.sections"
              :item-key="'id'"
              :group="{ name: 'panels' }"
              :options="{ animation: 150, handle: '.handle' }"
              class="block-rows is-flex has-flex-direction-column is-flex-wrap-nowrap"
            >
              <template #item="{ element, index }">
                <PanelSection
                  :key="index"
                  :label="`${rowIndex + 1}.${index + 1}`"
                  :scale="roofTableScale"
                  :section="element"
                  :active="activePanel === element"
                  @click="activePanel = element"
                  @remove="
                    ($event) => {
                      row.sections.splice(index, 1)
                      activePanel = null
                    }
                  "
                />
              </template>
            </draggable>
            <div class="row-info">
              <div class="is-size-4">{{ rowIndex + 1 }}</div>
              <font-awesome-icon
                v-if="activeRoof.rows.length > 1"
                :icon="['fa', 'trash']"
                class="trash"
                @click="removeRow(rowIndex)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="is-size-7">{{ $t('placement.dashed_area_explanation') }}</div>
        <div v-if="roofCount > 1">
          <a class="is-primary is-small" @click="removeRoof">
            {{ $t('placement.remove_roof', { name: activeRoof.name }) }}
          </a>
        </div>
      </div>
      <div class="right" ref="right"></div>
    </div>
  </div>
</template>

<style scoped lang="sass">
.grid-container
  display: grid
  grid-template-columns: 0.25fr 1fr 0.25fr
  grid-template-rows: 0.25fr 1fr 0.25fr
  grid-column-gap: 0px
  grid-row-gap: 0px

  .left
    grid-area: 2 / 1 / 3 / 2
  .top
    grid-area: 1 / 2 / 2 / 3
  .grid-wrapper
    grid-area: 2 / 2 / 3 / 3
  .bottom
    grid-area: 3 / 2 / 4 / 3
  .right
    grid-area: 2 / 3 / 3 / 4

  .left
    display: inline-flex
    justify-content: flex-end
    margin-right: 0.5rem
    .text
      align-self: center
    .line
      display: inline-flex
      span:first-child
        border: 0px solid black
        border-width: 1px 1px 1px 0px
        width: 10px
        height: 100%
        display: block
      span:last-child
        border: 0px solid black
        border-width: 1px 0px 1px 0px
        width: 10px
        height: 100%
        display: block
  .top
    align-self: flex-end
    margin-bottom: 0.5rem
    .text
      align-self: center
    .line
      span:first-child
        border: 0px solid black
        border-width: 0px 1px 1px 1px
        width: 100%
        height: 10px
        display: block
      span:last-child
        border: 0px solid black
        border-width: 0px 1px 0px 1px
        width: 100%
        height: 10px
        display: block

  .bottom
    display: inline-flex
    justify-content: space-between
    margin-top: 0.5rem
    .button
      margin-left: 0.5rem
.grid-wrapper
  padding: 1.5rem
  background: repeating-linear-gradient(-45deg, #ff7e7e80, #ff7e7e80 1px, #fff 2px, #fff 20px)
  outline: 2px solid lightgray
  .grid-content
    outline: 2px solid lightgray
    .block-wrapper
      .block-rows
        flex: 1
        background-color: #f5f5f5

.row-info
  display: flex
  position: absolute
  right: -5rem
  font-weight: bold
  align-items: center
  height: 100%
  .trash
    padding: 0.5rem 0.5rem 0.5rem 0
    color: #ff7e7e
    font-size: 1rem
    margin-left: 0.5rem
    cursor: pointer
</style>
