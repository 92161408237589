<script setup lang="ts">
import type { Step } from '@/project'

import { ref, watch } from 'vue'
import { project, conditionsValid } from '@/helpers'

import ProjectHeader from '@/components/ProjectHeader.vue'
import StepConditions from '@/components/steps/StepConditions.vue'
import StepPlacement from '@/components/steps/StepPlacement.vue'
import StepSummary from '@/components/steps/StepSummary.vue'

import { useI18n } from 'vue-i18n'
import RoofHandlingHeader from '@/components/headers/RoofHandlingHeader.vue'
import ProjectNameHeader from '@/components/headers/ProjectNameHeader.vue'
import RoofSummaryHeader from '@/components/headers/RoofSummaryHeader.vue'
const { t } = useI18n({ useScope: 'global' })

const steps: Step[] = [
  {
    name: 'conditions',
    label: t('project.conditions'),
    description: {
      content: t('project.description.conditions')
    },
    component: StepConditions,
    header: ProjectNameHeader
  },
  {
    name: 'placement',
    label: t('project.placement'),
    description: {
      // content: t('project.description.placement')
    },
    component: StepPlacement,
    header: RoofHandlingHeader
  },
  {
    name: 'summary',
    label: t('project.summary'),
    description: {
      // content: t('project.description.summary')
    },
    component: StepSummary,
    header: RoofSummaryHeader
  }
]

const transitionClass = ref<string>('slide-fade-left')
watch(
  () => [project.step, project.roofIndex],
  (value: number[], old: number[]) => {
    const [step, index] = value
    const [oldStep, oldIndex] = old
    transitionClass.value =
      step > oldStep || index > oldIndex ? 'slide-fade-left' : 'slide-fade-right'
  }
)
</script>

<template>
  <main>
    <ProjectHeader :steps="steps" :disabled="!conditionsValid">
      <component :is="steps[project.step - 1].header" />
    </ProjectHeader>
    <transition :name="transitionClass" mode="out-in">
      <component
        :is="steps[project.step - 1].component"
        :key="`${project.roofIndex}-${project.step}`"
        :class="'intro-' + transitionClass"
      />
    </transition>
  </main>
</template>

<style lang="sass">
main
  max-width: 880px
</style>
