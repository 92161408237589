<script setup lang="ts">
import type { FormatOptions } from '@/project'
import { computed, ref, watch } from 'vue'

import { markInputValue } from '@/helpers'

const prop = defineProps<{
  modelValue?: number | string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  step?: number
  disallowZero?: boolean
  asRaw?: boolean // Re-name this?
  formatOptions?: FormatOptions
  error?: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: number | string | undefined): void
  (e: 'blur', value: FocusEvent): void
  (e: 'focus', value: FocusEvent): void
}>()

const rawValue = ref<number | undefined>() // Never modify directly
const formattedValue = ref<string | undefined>() // Never modify directly
const allowErrors = ref(false)
const displayErrors = ref(false)

const opts = computed(() => {
  return {
    minimumFractionDigits: prop.formatOptions?.minimumFractionDigits ?? 0,
    maximumFractionDigits: prop.formatOptions?.maximumFractionDigits ?? 999,
    minimumSignificantDigits: prop.formatOptions?.minimumSignificantDigits ?? 0,
    maximumSignificantDigits: prop.formatOptions?.maximumSignificantDigits ?? 21,
    groupSeparator: prop.formatOptions?.groupSeparator ?? ' ',
    groupSize: prop.formatOptions?.groupSize ?? 3,
    decimalSeparator: prop.formatOptions?.decimalSeparator ?? ','
  }
})

const toRaw = (value: string | number | undefined) => {
  let parsed = undefined as number | undefined
  switch (typeof value) {
    case 'number':
      parsed = value
      break
    case 'string':
      value =
        value.match(
          new RegExp(`[0-9${opts.value.groupSeparator}]+${opts.value.decimalSeparator}?[0-9]*`)
        )?.[0] ?? value
      if (value === '') {
        parsed = undefined
      } else {
        parsed = Number(
          value.replace(opts.value.groupSeparator, '').replace(opts.value.decimalSeparator, '.')
        )
      }
      break
    default:
      return undefined
  }

  return parsed !== undefined ? (isNaN(parsed) ? undefined : parsed) : undefined // NaN check
}

const toFormatted = (value: number | string | undefined) => {
  let formatted = value?.toString() as string | undefined
  let integer, decimal
  switch (typeof value) {
    case 'number':
      ;[integer, decimal] = formatted?.split('.') ?? []
      break
    case 'string':
      formatted =
        formatted?.replace(new RegExp(`[^${opts.value.decimalSeparator}\\d]`, 'g'), '') ?? ''
      if (formatted === '') {
        return ''
      }
      ;[integer, decimal] = formatted
        .replace(new RegExp(opts.value.groupSeparator, 'g'), '')
        .split(opts.value.decimalSeparator)
      break
    default:
      return ''
  }

  if (integer === undefined || integer === '' || (prop.disallowZero === true && integer === '0')) {
    return ''
  }

  // Limit the integer part to the specified number of digits
  if (opts.value.maximumSignificantDigits > 0) {
    decimal = decimal || integer.slice(opts.value.maximumSignificantDigits)
    integer = integer.slice(0, opts.value.maximumSignificantDigits)
  }

  // Check for some weird edge cases
  const hasTrailingComma =
    formatted?.endsWith(opts.value.decimalSeparator) && opts.value.maximumFractionDigits > 0
  const hasDecimals =
    decimal !== undefined && decimal !== '' && opts.value.maximumFractionDigits > 0

  // Add group separators
  const groups = [] as string[]
  let group = ''
  for (let i = integer.length - 1; i >= 0; i--) {
    group = integer[i] + group
    // if the group is full, or we're at the end of the integer part, add it to the groups array
    if (group.length === opts.value.groupSize || i === 0) {
      groups.push(group)
      group = ''
    }
  }

  if (groups) integer = groups.reverse().join(opts.value.groupSeparator)

  // Constrain decimal part to the specified number of digits
  if (hasDecimals) {
    if (opts.value.maximumFractionDigits === 0) {
      decimal = ''
    } else {
      decimal = decimal.slice(0, opts.value.maximumFractionDigits)
    }
    while (decimal.length < opts.value.minimumFractionDigits) {
      decimal += '0'
    }
  }

  return `${integer}${
    hasDecimals
      ? opts.value.decimalSeparator + decimal
      : hasTrailingComma
      ? opts.value.decimalSeparator
      : ''
  }`
}

const onKeyPress = function (e: KeyboardEvent) {
  if (e.key === '.' && opts.value.decimalSeparator !== '.') {
    e.preventDefault()
    e.stopPropagation()
    value.value += opts.value.decimalSeparator
    return false
  }
}

const onFocus = function (e: FocusEvent) {
  allowErrors.value = true
  emit('focus', e)
}

const onBlur = function (e: FocusEvent) {
  emit('blur', e)
}

const value = computed({
  get() {
    return formattedValue.value
  },
  set(value) {
    formattedValue.value = undefined
    formattedValue.value = toFormatted(value)
    rawValue.value = toRaw(formattedValue.value)
    if (prop.asRaw) {
      emit('update:modelValue', rawValue.value)
    } else {
      emit('update:modelValue', formattedValue.value)
    }
  }
})

watch(
  () => prop.modelValue,
  (val) => {
    const raw = toRaw(val as string)
    if (raw === rawValue.value) return

    rawValue.value = raw
    formattedValue.value = toFormatted(val)
  },
  { immediate: true }
)

let errorTimeout: ReturnType<typeof setTimeout> = setTimeout(() => 0, 0)
watch(
  () => prop.error,
  (e) => {
    if (!e) {
      clearTimeout(errorTimeout)
      displayErrors.value = false
    } else {
      clearTimeout(errorTimeout)
      errorTimeout = setTimeout(() => {
        displayErrors.value = true
      }, 1000)
    }
  },
  { immediate: true }
)
</script>

<template>
  <div>
    <div
      data-balloon-visible
      data-balloon-blunt
      data-balloon-pos="down"
      :aria-label="allowErrors && displayErrors ? prop.error : undefined"
      class="balloon-error"
    >
      <input
        class="input"
        :class="{ 'is-danger': prop.error }"
        :placeholder="prop.placeholder"
        :required="prop.required"
        :disabled="prop.disabled"
        v-model="value"
        @click="markInputValue"
        @keypress="onKeyPress"
        @blur="onBlur"
        @focus="onFocus"
      />
      <slot></slot>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.balloon-error
  --balloon-color: #a6192e
  &:after
    pointer-events: none
</style>
