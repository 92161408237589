import type { PanelDimensions, Roof } from '@/project.d'
import type { ArticleResults, ResultRow } from '@/types'
import {
  TerrainType,
  WindZone,
  SnowZone,
  GenericYesNo,
  Optimizers,
  RoofType,
  LathDimension,
  TileFamily,
  TileFinish,
  TileColor
} from '@/options.d'

import { defineStore } from 'pinia'

import _ from 'lodash'
import { nanoid } from 'nanoid'

import { i18n } from '@/i18n'
const t = i18n.global.t

// Ugly deferred import to circumvent circular dependency
let minTakbredd: Function, collectArticles: (roofs: Roof[]) => ArticleResults, minTakfall: Function
import('@/helpers').then((helpers) => {
  minTakbredd = helpers.minTakbredd
  minTakfall = helpers.minTakfall
  collectArticles = helpers.collectArticles
})

const defaults = {
  roof: {
    name: 'Tak 1',
    id: nanoid(),
    rows: [{ id: nanoid(), sections: [] }]
  } as Roof
}

export const useProjectStore = defineStore({
  id: 'project',
  state: () => ({
    step: 1,
    roofIndex: 0,
    projectName: '',
    selections: {
      terrain_type: TerrainType.None,
      wind_zone: WindZone.None,
      snow_zone: SnowZone.None,
      optimizer: Optimizers.None,
      roof_type: RoofType.None,
      angle_over_45: GenericYesNo.None,
      lath_dimension: LathDimension.None,
      tile_family: TileFamily.None,
      tile_finish: TileFinish.None,
      tile_color: TileColor.None,
      panel: {
        height: 1722,
        width: 1134,
        thickness: 30
      } as PanelDimensions,
      roofs: [] as Roof[]
    },
    calculated: {
      summary: [
        {
          artnr: '180420',
          total: 120
        },
        {
          artnr: '180520',
          total: 120
        },
        {
          artnr: '180307',
          total: 12
        },
        {
          artnr: '180310',
          total: 12
        },
        {
          artnr: '180311',
          total: 12
        },
        {
          artnr: '180319',
          total: 12
        },
        {
          artnr: '180328',
          total: 12
        },
        {
          artnr: '180332',
          total: 12
        }
      ] as ResultRow[],
      weight: 0
    }
  }),
  getters: {
    selectionErrors(state) {
      const errors = {} as { panel_height: string; panel_width: string }
      if (
        !state.selections.panel.height ||
        state.selections.panel.height < 900 ||
        state.selections.panel.height > 2200
      ) {
        errors.panel_height = t('error.panel_height_out_of_range')
      }
      if (
        !state.selections.panel.width ||
        state.selections.panel.width < 900 ||
        state.selections.panel.width > 2200
      ) {
        errors.panel_width = t('error.panel_width_out_of_range')
      }

      return errors
    },
    conditionsValid(state) {
      const valueIsEmpty = (value: any, allowZero: boolean = false) =>
        value === null ||
        value === undefined ||
        value === '' ||
        (!allowZero && (value === 0 || value === '0'))

      for (const value of Object.values(state.selections)) {
        if (valueIsEmpty(value, true)) {
          return false
        }
      }
      // Custom check for panels
      if (
        valueIsEmpty(state.selections.panel.thickness) ||
        !valueIsEmpty(this.selectionErrors.panel_height) ||
        !valueIsEmpty(this.selectionErrors.panel_width)
      ) {
        return false
      }
      return true
    },
    activeRoof: (state) => state.selections.roofs[state.roofIndex],
    roofCount: (state) => state.selections.roofs.length,
    minTakbredd(state) {
      if (!state.selections.roofs?.[state.roofIndex]) return -1
      return minTakbredd()
    },
    minTakfall(state) {
      if (!state.selections.roofs?.[state.roofIndex]) return -1
      return minTakfall()
    },
    computedArticles(state): { artnr: string; total: number }[] {
      const articles = collectArticles(state.selections.roofs)
      // Convert artnr: total to {artnr, total}
      return Object.keys(articles).map((artnr) => ({ artnr, total: articles[artnr] }))
    }
  },
  actions: {
    setWeight(weight: number) {
      this.calculated.weight = weight
    },
    setStep(step: number) {
      this.step = Math.max(step, 1)
      this.setRoofIndex(0) // Always reset roof index when changing step
    },
    incrementStep(size = 1) {
      this.setStep(Math.max(this.step + size, 1))
    },
    setRoofIndex(index: number) {
      this.roofIndex = index

      // if no roofs, add one
      if (this.selections.roofs.length === 0) {
        this.addNewRoof()
      }
    },
    getRoofById(id: string): Roof | null {
      return this.selections.roofs.find((r) => r.id === id) || null
    },
    addNewRoof() {
      const roof = _.cloneDeep(defaults.roof)
      roof.name = `Tak ${this.selections.roofs.length + 1}`
      roof.id = nanoid()
      this.selections.roofs.push(roof)
    },
    deleteRoof(index: number) {
      this.selections.roofs.splice(index, 1)
      // if no roofs, add one (We should always have at least one roof)
      if (this.selections.roofs.length === 0) {
        this.addNewRoof()
      }
    },
    resetRoof(index: number) {
      if (this.selections.roofs.length - 1 < index) return
      this.selections.roofs[index] = _.merge(
        this.selections.roofs[index],
        _.cloneDeep(defaults.roof)
      )
    }
  }
})
