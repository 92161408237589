<script setup lang="ts">
import type { SectionPanel, SectionObstacle } from '@/project.d'
import { SectionType, SectionOrientation } from '@/project.d'

import { computed } from 'vue'
import { selections } from '@/helpers'

const ratio = computed(() => {
  if (
    prop.section.type !== SectionType.Obstacle &&
    prop.section.orientation === SectionOrientation.Horizontal
  ) {
    return {
      width: selections.value.panel.height / selections.value.panel.width,
      height: 1
    }
  }
  return {
    width: 1,
    height: selections.value.panel.height / selections.value.panel.width
  }
})

const baseSize = computed(() => {
  return 75 * (prop.scale || 1)
})

const width = computed(() => {
  if (prop.section.type === SectionType.Obstacle) {
    return baseSize.value * (prop.section.width / selections.value.panel.width)
  }
  return baseSize.value * ratio.value.width
})

const height = computed(() => {
  return baseSize.value * ratio.value.height
})

const fontSize = computed(() => {
  return Math.min(width.value, height.value) / 10
})

const labelFontSize = computed(() => {
  return fontSize.value + fontSize.value * (prop.scale ?? 1)
})

const prop = defineProps<{
  label?: string
  scale?: number
  active?: boolean
  section: SectionPanel | SectionObstacle
}>()

defineEmits<{
  (event: 'click'): void
  (event: 'remove'): void
}>()
</script>

<template>
  <div
    class="section-group nowrap"
    :class="{ 'is-obstacle': section.type === SectionType.Obstacle }"
    @click="$emit('click')"
  >
    <div class="section-label" :style="`font-size: ${labelFontSize}px`">
      {{ label }}
    </div>
    <font-awesome-icon
      v-if="active"
      :icon="['fa', 'trash']"
      class="trash"
      @click.stop="$emit('remove')"
    />
    <template v-if="section.type === SectionType.Panel">
      <svg
        v-for="i in section.amount"
        :key="i"
        :width="width"
        :height="height"
        :viewBox="`0 0 ${width} ${height}`"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect :width="width" :height="height" fill="#225e8a" stroke="black" :stroke-width="2" />
      </svg>
    </template>
    <template v-else-if="section.type === SectionType.Obstacle">
      <svg
        :width="width"
        :height="height"
        :viewBox="`0 0 ${width} ${height}`"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect :width="width" :height="height" fill="white" stroke="black" :stroke-width="2" />
        <line
          :x1="width"
          y1="0"
          x2="0"
          :y2="height"
          stroke="gray"
          :stroke-width="1"
          stroke-linecap="round"
        />
      </svg>
      <div
        class="section-text is-flex is-flex-direction-column"
        :style="{
          fontSize: `${fontSize}px`
        }"
      >
        <span>{{ $t('placement.panel.obstacle') }}</span>
        <span>{{ $t('generic.width') }}</span>
        <span>{{ section.width }} mm</span>
      </div>
    </template>
  </div>
</template>

<style scoped lang="sass">
@import "@/assets/variables.sass"

.section-group
  position: relative
  display: flex
  margin: 0.1rem

.section-group.active
  outline: 2px solid red
  z-index: 10

.section-group:hover
  cursor: pointer
  outline: 1px solid red
  z-index: 10

.section-text
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  text-align: center
  /* font-size: 1rem */
  /* font-weight: bold */
  text-transform: uppercase
  user-select: none

.trash
  position: absolute
  top: 0rem
  right: 0rem
  padding: 0.25rem
  font-size: 1.5rem
  color: #fff
  z-index: 10
  cursor: pointer
  &:hover
    color: #f00 !important

.section-label
  position: absolute
  z-index: 10
  color: #fff
  margin-left: 0.25rem

.is-obstacle
  .trash, .section-label
    color: #000
</style>
