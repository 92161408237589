import { storeToRefs } from 'pinia'
import { useProjectStore } from '@/store/project'
import pinia from '@/store/store'
import type { RoofRow, Roof } from '@/project.d'
import { SectionOrientation, SectionType } from '@/project.d'
import { Optimizers } from '@/options.d'
import {
  MONTAGESKENA_LEN,
  MONTAGESKENA_SMALLEST_REST,
  mountingTileVariantMap,
  PANEL_MARGIN,
  SECTION_END_MARGIN,
  minRadlängd
} from './common'
import type { ArticleResults } from '@/types.d'

const project = useProjectStore(pinia)

const { selections } = storeToRefs(project)

/**
 * Calculate number of infästningspannor. Depends on snowzone
 * Variable domains: Rows [+Vertical, +Horizontal], Snow zone [+< 3, +>= 3], Wind zone [+>= 25, +< 25], Terrain type [+0, +!0]
 */
export function calcInfästningspannor(rows: RoofRow[]): number {
  const panel = selections.value?.panel
  const snowZone = selections.value?.snow_zone
  if (!rows || !panel) return 0

  let sum = 0
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i]
    for (const section of row.sections)
      if (section.type == SectionType.Panel) {
        const snowZoneFactor = Number(snowZone) < 3 ? 1200 : 900

        if (section.orientation == SectionOrientation.Vertical)
          sum += 2 * (Math.ceil(((section.amount * panel.width) - 1200) / Number(snowZoneFactor)) + 3)
        else if (section.orientation == SectionOrientation.Horizontal)
          sum += 2 * (Math.ceil(((section.amount * panel.height) - 1200) / Number(snowZoneFactor)) + 3)
      }
  }
  return sum
}

/**
 * Calculate number of montageskenor. Splits rails across rows if the rest piece is at least 1m. Skarv-calculation is performed here as well on each split
 */
export function calcMontageskena(rows: RoofRow[]): { monSkenor: number; skarvar: number } {
  const panelSize = selections.value?.panel
  if (!rows || !panelSize) return { monSkenor: 0, skarvar: 0 }

  let monSkenor = 0
  let rest = 0
  let skarvar = 0
  for (const row of rows)
    for (const section of row.sections)
      if (section.type == SectionType.Panel)
        for (let i = 0; i < 2; i++) {
          const panelExtent =
            section.orientation == SectionOrientation.Vertical ? panelSize.width : panelSize.height
          const rowLen =
            panelExtent * section.amount +
            PANEL_MARGIN * (section.amount - 1) +
            SECTION_END_MARGIN * 2
          const rowLenAfterRest = Math.max(rowLen - rest, 0)
          rest = Math.max(rest - rowLen, 0)
          const newMonSkenor = Math.ceil(rowLenAfterRest / MONTAGESKENA_LEN)
          monSkenor += newMonSkenor
          // En skarv för varje skenände
          skarvar += 1 + (rowLen != rowLenAfterRest ? 1 : 0) + newMonSkenor

          if (rest == 0) {
            // Händer om vi behövde använda upp all rest för att täcka den här raden
            rest = newMonSkenor * MONTAGESKENA_LEN - rowLenAfterRest

            // Om reststycket är mindre än 1m, släng det
            if (rest < MONTAGESKENA_SMALLEST_REST) rest = 0
          }
          if (rest > 0)
            if (rest < MONTAGESKENA_SMALLEST_REST)
              // Händer om vi kunde täcka det här stycket med enbart restdelen
              rest = 0
        }
  return { monSkenor, skarvar }
}

/**
 * Calculate panelklämmor. Sum of number of panels * 2 + 2 over all rows.
 * Variable domains: Rows [+Has panels, +Has no panels]
 */
export function calcPanelklämmor(rows: RoofRow[]) {
  if (!rows) return 0

  let sum = 0
  for (const row of rows)
    for (const section of row.sections)
      if (section.type == SectionType.Panel) sum += section.amount * 2 + 2
  return sum
}

/**
 * Calculate gavlar. 4 per each panelsection.
 * Variable domains: Rows [+Has panels, +Has no panels]
 */
export function calcGavlar(rows: RoofRow[]) {
  if (!rows) return 0

  const gavlarPerSection = 4

  let sections = 0
  for (const row of rows)
    for (const section of row.sections)
      if (section.type == SectionType.Panel)
        sections++

  return gavlarPerSection * sections
}

/**
 * Calculate skruv in packs of 20 (TODO: verify). Calculate as half of the number of rows
 * Variable domains: Sections [+Sections with panels, +Sections with only obstacles]
 */
export function calcSkruv(rows: RoofRow[]): number {
  if (!rows) return 0

  return Math.ceil(
    rows.filter((row) => row.sections.some((section) => section.type == SectionType.Panel)).length /
      2
  )
}

/**
 * Calculate optimerarfäste. New addition since the excel is that the user can select up to 2 units per panel.
 * Variable domains: Rows [+empty, +non-empty], Optimizer [+None, +One, +OnePerTwo]
 */
export function calcOptimerarfäste(rows: RoofRow[]): number {
  if (!rows) return 0

  let perPanel = 0
  if (selections.value.optimizer == Optimizers.OnePerPanel) perPanel = 1
  if (selections.value.optimizer == Optimizers.OnePerTwoPanels) perPanel = 0.5

  let sum = 0
  for (const row of rows)
    for (const section of row.sections) if (section.type == SectionType.Panel) sum += section.amount
  return Math.ceil(perPanel * sum)
}

export function calcDemonteringsverktyg(): number {
  return 1
}

/**
 * Collect all articles calculated from the store's current configuration.
 */
export function collectArticles(roofs: Roof[]): ArticleResults {
  const results = {} as ArticleResults

  const läkt = selections.value?.lath_dimension
  const frameThickness = selections.value?.panel?.thickness
  const tileFinish = selections.value?.tile_finish
  const tileColor = selections.value?.tile_color
  if (!läkt || !frameThickness || !tileFinish || !tileColor) {
    console.warn(
      'Missing selections for article calculation: ',
      'läkt',
      läkt,
      'frameThickness',
      frameThickness,
      'tileFinish',
      tileFinish,
      'tileColor',
      tileColor
    )
    return results
  }

  for (const roof of roofs) {
    const result = {} as ArticleResults

    // Infästningspanna
    result[mountingTileVariantMap[läkt][tileFinish][tileColor]] = calcInfästningspannor(roof.rows)

    // Montageskena
    const monSkenKapning = calcMontageskena(roof.rows)
    result['180307'] = monSkenKapning.monSkenor

    // Panelklämma 30-35mm
    result['180310'] = calcPanelklämmor(roof.rows)

    // Gavel30/35mm
    if (frameThickness == 30) {
      result['180311'] = calcGavlar(roof.rows)
    } else {
      result['180312'] = calcGavlar(roof.rows)
    }

    // Skarv
    result['180319'] = monSkenKapning.skarvar

    // Skruv 20st
    result['180320'] = calcSkruv(roof.rows)

    // Optimerarfäste
    result['180328'] = calcOptimerarfäste(roof.rows)

    for (const key in result) {
      if (results[key]) {
        results[key] += result[key]
      } else if (result[key] > 0) {
        results[key] = result[key]
      }
    }
  }

  // Demonteringsverktyg
  results['180332'] = calcDemonteringsverktyg()

  return results
}
