import { createApp } from 'vue'
import pinia from '@/store/store'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
// import ls from '@livesession/sdk'

import App from './App.vue'
import router from './router'

import 'iframe-resizer/js/iframeResizer.contentWindow'

// Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCaretRight,
  faChevronUp,
  faChevronDown,
  faArrowLeftLong,
  faXmark,
  faCheck,
  faTrash
} from '@fortawesome/pro-solid-svg-icons'
import { faCalendar, faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import { faPen } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(
  faCaretRight,
  faCalendar,
  faChevronUp,
  faChevronDown,
  faArrowLeftLong,
  faPen,
  faXmark,
  faCheck,
  faCircleInfo,
  faTrash
)

import 'bulma/bulma.sass'
import 'balloon-css/balloon.min.css'
import '@/assets/app.sass'
// import '@/assets/mobile.sass'

const app = createApp(App)
import { i18n } from './i18n'

if (import.meta.env.VITE_APP_ENV && import.meta.env.VITE_APP_ENV !== 'development') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', 'solpanelsinfastning.benders-k8s.duvadev.se', /^\//]
      })
    ],
    tracesSampleRate: 0.1
  })
}

// const lsOptions = {} as {
//   rootHostname?: string
// }
// const sdkOptions = {
//   devMode: process.env.NODE_ENV === 'development'
// } as {
//   devMode: boolean
//   scriptURL: string
// }
// if (process.env.NODE_ENV === 'production') {
//   lsOptions.rootHostname = '.benders.se'
// } else if (process.env.NODE_ENV === 'staging') {
//   lsOptions.rootHostname = '.benders-k8s.duvadev.se'
// }
// ls.init('3fcf25c4.139d7b04', lsOptions, sdkOptions)
// ls.newPageView()

app.use(pinia)
app.use(router)
app.use(i18n)
app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')
