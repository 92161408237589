<script setup lang="ts">
import type { SectionPanel, SectionObstacle } from '@/project.d'

import { ref, computed, watch } from 'vue'
import { SectionType, SectionOrientation } from '@/project.d'

import { activeRoof } from '@/helpers'

import FormattedNumber from '@/components/FormattedNumber.vue'
import ImageRadioSelector from '../ImageRadioSelector.vue'

import _ from 'lodash'
import { nanoid } from 'nanoid'
import RoofPlacement from '@/components/RoofPlacement.vue'
import { useI18n } from 'vue-i18n'

import panelVertical from '@/assets/images/panel-vertical.svg'
import panelHorizontal from '@/assets/images/panel-horizontal.svg'
import obstacleHorizontal from '@/assets/images/panel-obstacle.svg'

const { t } = useI18n()

const selected_defaults = {
  type: SectionType.Panel,
  orientation: SectionOrientation.Vertical,
  amount: 1,
  width: 0,
  row: 1
} as {
  type: SectionType
  orientation: SectionOrientation
  amount: number
  width: number
  row: number
}
const selected = ref(_.cloneDeep(selected_defaults))
const selected_section = computed({
  get: () => {
    return `${selected.value.type}-${selected.value.orientation}`
  },
  set: (value: string) => {
    selected.value.type = optionMap[value].type
    selected.value.orientation = optionMap[value].orientation
  }
})

const optionMap = {
  'panel-vertical': {
    type: SectionType.Panel,
    orientation: SectionOrientation.Vertical
  },
  'panel-horizontal': {
    type: SectionType.Panel,
    orientation: SectionOrientation.Horizontal
  },
  'obstacle-horizontal': {
    type: SectionType.Obstacle,
    orientation: SectionOrientation.Horizontal
  }
} as { [key: string]: { type: SectionType; orientation: SectionOrientation } }

const section_options = [
  {
    label: t('placement.panel.vertical'),
    value: 'panel-vertical',
    image: panelVertical
  },
  {
    label: t('placement.panel.horizontal'),
    value: 'panel-horizontal',
    image: panelHorizontal
  },
  {
    label: t('placement.panel.obstacle'),
    value: 'obstacle-horizontal',
    image: obstacleHorizontal
  }
]

const addSection = () => {
  const rowIndex = selected.value.row == 0 ? activeRoof.value.rows.length : selected.value.row - 1
  if (!activeRoof.value.rows[rowIndex]) {
    activeRoof.value.rows[rowIndex] = { id: nanoid(), sections: [] }
    selected.value.row = rowIndex + 1
  }

  switch (selected.value.type) {
    case SectionType.Panel:
      activeRoof.value.rows[rowIndex].sections.push({
        type: SectionType.Panel,
        orientation: selected.value.orientation,
        amount: selected.value.amount
      } as SectionPanel)
      break
    case SectionType.Obstacle:
      activeRoof.value.rows[rowIndex].sections.push({
        type: SectionType.Obstacle,
        orientation: selected.value.orientation,
        width: selected.value.width
      } as SectionObstacle)
      break
  }

  // selected.value = _.cloneDeep(selected_defaults)
}

const error = computed(() => {
  const errors = {
    section_amount: '',
    obstacle_width: ''
  }

  // Require at least 1 panel, regardless of orientation
  // If the selected panel is vertical, the max amount of allowed panels is 12
  // If the selected panel is hotizontal, the max amount of allowed panels is 8

  switch (selected.value.orientation) {
    case SectionOrientation.Vertical:
      if (
        selected.value.amount < 1 ||
        selected.value.amount > 12 ||
        selected.value.amount === undefined
      ) {
        errors.section_amount = t('placement.errors.vertical_panels')
      }
      break
    case SectionOrientation.Horizontal:
      if (
        selected.value.amount < 1 ||
        selected.value.amount > 8 ||
        selected.value.amount === undefined
      ) {
        errors.section_amount = t('placement.errors.horizontal_panels')
      }
      break
  }

  // min obstacle width is 100, max is 10000 (Placeholder values)
  if (
    selected.value.width < 100 ||
    selected.value.width > 10000 ||
    selected.value.width === undefined
  ) {
    errors.obstacle_width = t('placement.errors.obstacle_width')
  }

  return errors
})

const addSectionDisabled = computed(() => {
  if (!!error.value.section_amount && selected.value.type === SectionType.Panel) {
    return true
  }
  if (!!error.value.obstacle_width && selected.value.type === SectionType.Obstacle) {
    return true
  }

  return false
})

watch(
  () => activeRoof.value.rows,
  (rows) => {
    if (selected.value.row > rows.length) {
      selected.value.row = rows.length
    }
  },
  { deep: true }
)
</script>

<template>
  <div class="substep">
    <div class="section">
      <h2 class="benders-small-title is-uppercase">{{ $t('placement.add_section') }}</h2>
      <div class="placement-options">
        <div class="section-options is-flex has-flex-direction-row is-justify-content-center">
          <div class="section-orientation">
            <label for="section-orientation" class="benders-small-title">
              {{ $t('section.orientation') }}
            </label>
            <ImageRadioSelector
              :items="section_options"
              group="section_orientation"
              v-model="selected_section"
              class="pt-0"
            >
              <template #image="{ item }">
                <img :src="item.image" :alt="item.label" />
              </template>
              <template #label="{ item }">
                <span>{{ item.label }}</span>
              </template>
            </ImageRadioSelector>
          </div>
          <div class="section-placement is-flex">
            <div class="section-wrapper">
              <template v-if="selected.type === SectionType.Panel">
                <div class="section-amount">
                  <label for="section-amount" class="benders-small-title">
                    {{ $t('generic.amount') }}
                  </label>
                  <FormattedNumber
                    id="section-amount"
                    class="mt-1"
                    v-model="selected.amount"
                    :format-options="{
                      maximumFractionDigits: 0
                    }"
                    :asRaw="true"
                    :min="0"
                    :error="error.section_amount"
                  />
                </div>
              </template>
              <template v-else>
                <div class="section-width">
                  <label for="section-width" class="benders-small-title">
                    {{ $t('generic.width') }}
                  </label>
                  <FormattedNumber
                    id="section-width"
                    class="is-relative mt-1"
                    v-model="selected.width"
                    :format-options="{
                      maximumFractionDigits: 2
                    }"
                    :asRaw="true"
                    :error="error.obstacle_width"
                  >
                    <span class="unit-overlay has-text-grey">mm</span>
                  </FormattedNumber>
                </div>
              </template>
              <div class="section-position">
                <label for="section-position" class="benders-small-title">
                  {{ $t('placement.placement') }}
                </label>
                <div class="select is-block">
                  <select v-model="selected.row" style="width: 100%">
                    <option v-for="row in activeRoof.rows.length" :key="row" :value="row">
                      Rad {{ row }}
                    </option>
                    <option value="0">{{ $t('placement.new_row') }}</option>
                  </select>
                </div>
              </div>
              <div class="section-row">
                <button
                  class="button is-uppercase has-text-weight-bold pl-4 pr-4 mt-4"
                  @click="addSection"
                  :disabled="addSectionDisabled"
                >
                  {{ $t('generic.add') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- Display 2d array of roof rows as blocks -->
      <!-- <h2 class="benders-small-title is-uppercase">{{ $t('placement.current_placement') }}</h2> -->
      <RoofPlacement />
    </div>
  </div>
</template>

<style lang="sass" scoped>
.section-orientation, .section-placement
  flex: 1

.button[disabled]
  background-color: original
</style>
