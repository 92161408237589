import { SectionOrientation, SectionType, type RoofRow, type PanelDimensions } from '@/project.d'
import { LathDimension, TileColor, TileFinish } from '@/options.d'

import pinia from '@/store/store'
import { useProjectStore } from '@/store/project'
import { storeToRefs } from 'pinia'

const { activeRoof, selections } = storeToRefs(useProjectStore(pinia))

export const ROOF_EDGE_MARGIN = 200 // 2dm margin between solar panel area and edge of roof
export const PANEL_MARGIN = 20 // 2cm margin between solar panels
export const MONTAGESKENA_LEN = 4800 // Montageskena length
export const MONTAGESKENA_SMALLEST_REST = 1000 // Smallest montageskena-segment we won't throw away
export const SECTION_END_MARGIN = 60 // 60mm margin before and after the panels on all rows

/** Article number map for infästningspanna */
export const mountingTileVariantMap = {
  [LathDimension['25x48']]: {
    [TileFinish.Benderit]: {
      [TileColor.Svart]: '180420',
      [TileColor.Tegelröd]: '180424'
    },
    [TileFinish.Candor]: {
      [TileColor.Svart]: '180480',
      [TileColor.Tegelröd]: '180484'
    }
  },
  [LathDimension['45x70']]: {
    [TileFinish.Benderit]: {
      [TileColor.Svart]: '180520',
      [TileColor.Tegelröd]: '180524'
    },
    [TileFinish.Candor]: {
      [TileColor.Svart]: '180580',
      [TileColor.Tegelröd]: '180584'
    }
  }
} as { [key in LathDimension]: { [key in TileFinish]: { [key in TileColor]: string } } }

/**
 * Calculate the minimum roof width this row requires. Sums all sections, obstacles, margin between panels and margins to the end of the roof
 */
export function minRadlängd(rowIndex: number, row: RoofRow, panel: PanelDimensions): number {
  if (!row || !panel) return -1

  let sum = 2 * ROOF_EDGE_MARGIN
  for (const section of row.sections) {
    if (section.type == SectionType.Panel) {
      const panelExtent =
        section.orientation == SectionOrientation.Vertical ? panel.width : panel.height
      sum += section.amount * panelExtent + (section.amount - 1) * PANEL_MARGIN
    }
    if (section.type == SectionType.Obstacle) {
      sum += section.width
    }
  }

  return sum
}

/**
 * Calculate the minimum width for this roof. Sums all sections, obstacles, margin between panels and margins to the end of the roof for each row
 */
export function minTakbredd(): number {
  const rows = activeRoof.value?.rows
  const panel = selections.value?.panel
  if (!rows || !panel) return -1

  return rows.reduce((max, val, index) => Math.max(max, minRadlängd(index, val, panel)), 0)
}

/**
 * Calculate the minimum takfall for this roof. Sums all row heights plus the roof edge margin
 */
export function minTakfall(): number {
  const rows = activeRoof.value?.rows
  const panel = selections.value?.panel
  if (!rows || !panel) return -1

  return rows.reduce((acc, row) => {
    // Find the highest panel on this row
    let highestPanel = 0
    if (
      row.sections.find(
        (section) =>
          section.type == SectionType.Panel && section.orientation == SectionOrientation.Vertical
      )
    )
      highestPanel = panel.height
    if (
      panel.width > highestPanel &&
      row.sections.find(
        (section) =>
          section.type == SectionType.Panel && section.orientation == SectionOrientation.Horizontal
      )
    )
      highestPanel = panel.width

    return acc + highestPanel
  }, 2 * ROOF_EDGE_MARGIN)
}
