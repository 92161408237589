<script setup lang="ts">
import { computed } from 'vue'
import { optionMap, selections } from '@/helpers'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const sections = computed(() => {
  return [
    {
      label: t('conditions.area'),
      rows: [
        {
          label: t('summary.conditions.terrain'),
          value: selections.value.terrain_type
        },
        {
          label: t('summary.conditions.wind_zone'),
          value: selections.value.wind_zone
        },
        {
          label: t('summary.conditions.snow_zone'),
          value: optionMap.snow_zone[selections.value.snow_zone]
        }
      ]
    },
    {
      label: t('conditions.solar_cells'),
      rows: [
        {
          label: t('summary.conditions.panel_height'),
          value: selections.value.panel.height
        },
        {
          label: t('summary.conditions.panel_width'),
          value: selections.value.panel.width
        },
        {
          label: t('summary.conditions.panel_thickness'),
          value: selections.value.panel.thickness
        },
        {
          label: t('summary.conditions.optimizer_mount'),
          value: optionMap.optimizers[selections.value.optimizer]
        }
      ]
    },
    {
      label: t('summary.conditions.roof_details'),
      rows: [
        {
          label: t('summary.conditions.roof_type'),
          value: optionMap.roof_type[selections.value.roof_type]
        },
        {
          label: t('summary.conditions.angle_over_45'),
          value: optionMap.genericyesno[selections.value.angle_over_45]
        },
        {
          label: t('summary.conditions.lath_dimension'),
          value: selections.value.lath_dimension
        },
        {
          label: t('summary.conditions.tile_family'),
          value: optionMap.tile_family[selections.value.tile_family]
        },
        {
          label: t('summary.conditions.tile_finish'),
          value: optionMap.tile_finish[selections.value.tile_finish]
        },
        {
          label: t('summary.conditions.tile_color'),
          value: optionMap.tile_color[selections.value.tile_color]
        }
      ]
    }
  ]
})
</script>

<template>
  <div class="grid-table">
    <table v-for="section in sections" :key="section.label">
      <thead>
        <tr>
          <th width="75%">{{ section.label }}</th>
          <th width="25%"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in section.rows" :key="row.label">
          <td>{{ row.label }}</td>
          <td>{{ row.value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="sass" scoped>
@import "@/assets/variables.sass"
@import "bulma/sass/utilities/mixins.sass"
.grid-table
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-template-rows: 1fr
  grid-column-gap: 1.25rem
  grid-row-gap: 0px

  > table:nth-child(1)
    grid-area: 1 / 1 / 2 / 2
  > table:nth-child(2)
    grid-area: 1 / 2 / 2 / 3
  > table:nth-child(3)
    grid-area: 1 / 3 / 2 / 4

  @include mobile
    grid-template-columns: repeat(1, 1fr)
    grid-template-rows: repeat(1, 1fr)
    grid-column-gap: 0px
    grid-row-gap: 0px

    > table:nth-child(1)
      grid-area: 1 / 1 / 2 / 2
    > table:nth-child(2)
      grid-area: 2 / 1 / 3 / 2
    > table:nth-child(3)
      grid-area: 3 / 1 / 4 / 2


table
  // display: block
  height: fit-content
  text-align: left
  border-collapse: separate
  border-spacing: 1.25rem 0.25rem
  white-space: nowrap
  tr:first-child
    th
      padding: 0.5rem 0.5rem 0.5rem 0
      font-weight: 600
      color: $gray-7

  td:first-child
    font-weight: 600
</style>
