<script setup lang="ts">
import type { FieldValues } from '@/types';

import { computed } from 'vue';

const props = defineProps<{
  modelValue: FieldValues;
  label?: string;
  roofId?: string;
}>();

function convertUnit(unit: string) {
  const unitConversion = {
    // ark: "Ark",
    // b: "Bricka",
    // bnt: "Bunt",
    // fl: "Flaska",
    // flm: "flm",
    // g: "Gram",
    // kg: "kg",
    // kkg: "Kkg",
    // krt: "Kartong",
    // l: "Liter",
    // lpm: "lpm",
    // m: "Meter",
    // m2: "m2",
    // m3: "m3",
    // ml: "ml",
    // mm: "mm",
    p: 'Pall',
    pal: 'Pall',
    p2: 'Halvpall',
    // par: "Par",
    // pkk: "Paket",
    // pkt: "Paket",
    // pås: "Påse",
    // rul: "Rulle",
    // set: "Set",
    // st: "Styck",
    // säc: "Säck",
    // t: "Ton",
    // ton: "Ton",
    // tub: "Tub",
  } as { [key: string]: string };
  return unitConversion[unit] || unit;
}

const value = computed(() => {
  // unit
  let unit = '';
  let perUnitAmount = 0;
  if (props.modelValue.article.unit) {
    [unit, perUnitAmount] = Object.entries(props.modelValue.article.unit).sort((x, y) => y[1] - x[1])[0];
  }

  const amount = props.modelValue.total / perUnitAmount;

  const value = {
    amount: amount % 1 === 0 ? amount : null, // Only show amount if it fits perfectly in the unit
    unit: convertUnit(unit),
    subtitle: {
      amount: props.modelValue.total,
      unit: props.modelValue.article.basicUnitOfMeasure || 'st',
    } as { amount: number; unit: string } | null,
  };

  if (!value.amount || !perUnitAmount || unit === value.subtitle?.unit) {
    value.amount = value.subtitle?.amount || 0;
    value.unit = value.subtitle?.unit || 'st';
    value.subtitle = null;
  }

  return value;
});
</script>

<template>
  <div class="is-flex is-justify-content-space-between">
    <div class="is-hidden-tablet has-text-weight-bold">
      {{ props.label }}
    </div>
    <div class="amount">
      <div>
        {{ value.amount }} <span>{{ value.unit }}</span>
      </div>
      <div v-if="value.subtitle" class="subtitle">
        {{ value.subtitle.amount }} <span>{{ value.subtitle.unit }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.subtitle
  font-size: 0.9rem

.amount
  min-width: 4.2rem
</style>
