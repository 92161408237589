<script setup lang="ts">
import { useFrameStore } from '@/store/frame'
const { updateFrameData } = useFrameStore()

window.addEventListener('message', (event) => {
  const data = event.data
  if (typeof data !== 'object' || data === null) {
    return
  }
  switch (data.type) {
    case 'bendersReady':
      window.parent.postMessage({ type: 'bendersInit' }, '*')
      break
    case 'bendersInit':
      updateFrameData(data.data)
      // if (data.data?.session) {
      //   setSession(data.data.session)
      // }
      break
    // case "bendersLoading":
    //   this.$store.dispatch("updateFrameData", {
    //     ...this.$store.state.frameData,
    //     loading: data.data,
    //   });
    //   break;
  }
})
</script>

<template>
  <section class="hero-body">
    <div class="container has-text-centered">
      <div class="columns is-centered">
        <RouterView class="column" />
      </div>
    </div>
  </section>
</template>

<style scoped>
header {
  line-height: 1.5;
  max-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

.hero-body {
  padding: 0;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    margin-top: 1rem;
  }
}
</style>
