<script setup lang="ts">
import { nextTick } from 'vue'
import { selections, roofIndex } from '@/helpers'
import { useProjectStore } from '@/store/project'

import ItemDisplay from '@/components/ItemDisplay.vue'

const { resetRoof, addNewRoof, setRoofIndex, deleteRoof } = useProjectStore()

const changeActiveRoof = (index: number) => {
  nextTick(() => {
    setRoofIndex(index)
  })
}

const newRoof = () => {
  addNewRoof()
  setRoofIndex(selections.value.roofs.length - 1)
}

const handleReset = () => {
  // Reset the current roof
  resetRoof(roofIndex.value)
}

const removeRoof = () => {
  const index = roofIndex.value
  // Remove
  setRoofIndex(Math.max(0, index - 1))
  nextTick(() => {
    deleteRoof(index)
  })
}
</script>

<template>
  <div class="roofs">
    <ItemDisplay
      :items="selections.roofs"
      :active="roofIndex"
      :max="8"
      @change="changeActiveRoof"
      @new="newRoof"
      @cancel="removeRoof()"
      @reset="handleReset"
    />
  </div>
</template>

<style lang="sass" scoped>
@import "@/assets/variables.sass"

.roofs
  border-bottom: 2px solid #ebebeb
  label
    display: block
</style>
