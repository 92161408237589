<script setup lang="ts">
import {
  GenericYesNo,
  LathDimension,
  TileFamily,
  TileFinish,
  TileColor,
  RoofType
} from '@/options.d'

import { project, enumToOptions, optionMap } from '@/helpers'

import RadioSelector from '@/components/RadioSelector.vue'

const roof_types = enumToOptions(RoofType, {
  labels: optionMap.roof_type
})
const angle_over_45 = enumToOptions(GenericYesNo, {
  labels: optionMap.genericyesno
})
const lath_dimensions = enumToOptions(LathDimension, {
  labels: optionMap.lath_dimension
})
const tile_families = enumToOptions(TileFamily)
const tile_finishes = enumToOptions(TileFinish)
const tile_color = enumToOptions(TileColor)
</script>

<template>
  <div class="section">
    <h2 class="benders-small-title is-uppercase">{{ $t('conditions.roof_details') }}</h2>
    <div class="roof-type">
      <h2 class="benders-small-title">{{ $t('conditions.roof_type') }}</h2>
      <RadioSelector :items="roof_types" group="roof_type" v-model="project.selections.roof_type" />
    </div>
    <div class="angle-over-45">
      <h2 class="benders-small-title">{{ $t('conditions.angle_over_45') }}</h2>
      <RadioSelector
        :items="angle_over_45"
        group="angle_over_45"
        v-model="project.selections.angle_over_45"
      />
    </div>
    <div class="lath-dimension">
      <h2 class="benders-small-title">{{ $t('conditions.lath_dimension') }}</h2>
      <RadioSelector
        :items="lath_dimensions"
        group="lath_dimension"
        v-model="project.selections.lath_dimension"
      />
    </div>
    <div class="tile-family">
      <h2 class="benders-small-title">{{ $t('conditions.tile_family') }}</h2>
      <RadioSelector
        :items="tile_families"
        group="tile_family"
        v-model="project.selections.tile_family"
      />
    </div>
    <div class="tile-finish">
      <h2 class="benders-small-title">{{ $t('conditions.tile_finish') }}</h2>
      <RadioSelector
        :items="tile_finishes"
        group="tile_finish"
        v-model="project.selections.tile_finish"
      />
    </div>
    <div class="tile-color">
      <h2 class="benders-small-title">{{ $t('conditions.tile_color') }}</h2>
      <RadioSelector
        :items="tile_color"
        group="tile_color"
        v-model="project.selections.tile_color"
      />
    </div>
  </div>
</template>
