import axios from 'axios'
import { useAccountStore } from '@/store/account'

const { getSession } = useAccountStore()

const instance = axios.create({
    baseURL: import.meta.env.VITE_API_PDF_API_BASE_URL,
})


// Intercept request and refresh Auth header
instance.interceptors.request.use((config) => {
    config.headers = config.headers ?? {}
    config.headers.Authorization = `Bearer ${getSession}`
    return config;
})


export function generatePDF(html: string, name: string) {
    return instance.post(`direct`, {
        apikey: "zcBUEZvnnO3hI4McbrhIbvygyFtKqyhA",
        html,
        name
    }, {
        responseType: 'blob'
    })
        .then(r =>
            r.data
        )
}

export function generateHTML(html: string) {
    return instance.post(`html`, {
        apikey: "zcBUEZvnnO3hI4McbrhIbvygyFtKqyhA",
        html,
    })
        .then(r =>
            r.data
        )
}
