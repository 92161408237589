export enum SectionType {
  Panel = 'panel',
  Obstacle = 'obstacle'
}

export enum SectionOrientation {
  Vertical = 'vertical',
  Horizontal = 'horizontal'
}

export interface SectionObstacle {
  type: SectionType.Obstacle
  width: number
}

export interface SectionPanel {
  type: SectionType.Panel
  orientation: SectionOrientation
  amount: number
}

export interface PanelDimensions {
  height: number,
  width: number,
  thickness: number
}

export interface Roof {
  name: string
  id: string
  rows: RoofRow[]
}

export interface RoofRow {
  id: string
  sections: (SectionPanel | SectionObstacle)[]
}

export interface Step {
  name: string
  label: string
  description: {
    content?: string
    component?: unknown // If component is defined, text is passed into the component as a prop
  }
  component: unknown
  header?: unknown
  // props?: any;
}

export interface RadioOption {
  label: string
  value: unknown
  key?: string
  disabled?: boolean
  description?: string
  artnr?: string // Used for article images
  image?: string // Custom image for the option
  default?: boolean // Default option
  article?: Article
}

export interface FormatOptions {
  decimalSeparator?: string
  groupSeparator?: string
  groupSize?: number
  minimumFractionDigits?: number
  maximumFractionDigits?: number
  minimumSignificantDigits?: number
  maximumSignificantDigits?: number
}
