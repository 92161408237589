<script setup lang="ts">
import type { RadioOption } from '@/project'

import { computed, watch } from 'vue'
// import { computedAsync } from '@vueuse/core'
// import { useArticleStore } from '@/store/article';

// import IconOptionCheck from '@/components/icons/IconOptionCheck.vue';
// import ArticleImage from '@/components/ArticleImage.vue';

// const { getArticle } = useArticleStore()

const value = computed({
  get: () => prop.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
    emit('change', value)
  }
})

// const blankImage = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
// const selected = computedAsync(async () => {
//   const sel = prop.items.find((o) => o.value === value.value)
//   if (sel && !sel.image && sel.artnr) {
//     sel.article = await getArticle(sel.artnr)
//   }
//   return sel
// })
// const image = computedAsync<string>(async () => {
//   if (selected.value?.image) {
//     return selected.value.image
//   } else if (selected.value?.artnr) {
//     const image = selected.value.article?.Images?.primary?.filepath ?? ''
//     if (image) {
//       return import.meta.env.VITE_IMAGE_BASE_URL.replace('{size}', '167x') + image
//     }
//   }

//   return blankImage
// }, blankImage)

// If only 1 option, select it
watch(
  () => prop.items,
  () => {
    if (prop.items.length === 1) {
      value.value = prop.items[0].value
    }
  },
  { immediate: true }
)

const cItems = computed(() => {
  return prop.items.map((o) => {
    return {
      ...o,
      key: o.value as string
    } as RadioOption
  })
})

const prop = defineProps<{
  withImage?: boolean
  imageBelow?: boolean
  group: string
  items: RadioOption[]
  modelValue: unknown
}>()
const emit = defineEmits<{
  (e: 'update:modelValue', value: unknown): void
  (e: 'change', value: unknown): void
}>()
</script>

<template>
  <div
    class="selector-wrapper"
    :class="{
      columns: withImage,
      'image-below': imageBelow
    }"
  >
    <div
      class="selector-wrapper-radios"
      :class="{
        column: withImage,
        'is-flex': withImage,
        'has-text-centered': !withImage
      }"
    >
      <div class="wrap">
        <div
          v-for="item in cItems"
          :key="item.key"
          class="radio benders-select-btn"
          data-balloon-blunt
          :data-balloon-pos="item.description ? 'up' : null"
          :aria-label="item.description"
        >
          <input
            type="radio"
            :id="group + item.value"
            :name="group"
            :value="item.value"
            :disabled="item.disabled"
            v-model="value"
          />
          <label :for="group + item.value">
            {{ item.label }}
          </label>
        </div>
      </div>
      <slot></slot>
    </div>
    <div v-if="withImage" class="selector-wrapper-show column is-flex">
      <figure>
        <!-- <IconOptionCheck :style="{ opacity: value ? '1' : '0' }" /> -->
        <!-- <ArticleImage :article="selected?.article">
          <img
            :key="selected?.artnr"
            :src="image"
            alt=""
            :title="selected?.artnr"
            class="img"
            :class="{ selected: value, 'quick-fade': true }"
            width="167"
            height="167"
          />
        </ArticleImage> -->
      </figure>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import "@/assets/variables.sass"

.selector-wrapper-show
  figure
    margin: 0

:not(.columns:not(.image-below)) > .selector-wrapper-radios .wrap
    max-width: unset
    @media screen and (min-width: 768px)
        text-align: inherit

.selector-wrapper.image-below
    flex-direction: column
    align-items: center
    .selector-wrapper-radios
        align-items: center
        padding-bottom: 0
        text-align: center
</style>
