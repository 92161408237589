<script setup lang="ts"></script>

<template>
  <div class="wrapper">
    <div class="description">
      {{ $t('summary.description') }}
    </div>
    <a href="https://www.benders.se/sunlight/" target="_blank" rel="noopener noreferrer">
      {{ $t('summary.description_link') }}
    </a>
  </div>
</template>

<style lang="sass" scoped>
.description
  margin: 0 4rem
</style>
