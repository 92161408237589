<script setup lang="ts">
import { PanelThickness, Optimizers } from '@/options.d'
import { project, enumToOptions, optionMap, selectionErrors as errors } from '@/helpers'

import FormattedNumber from '@/components/FormattedNumber.vue'
import RadioSelector from '@/components/RadioSelector.vue'

const panel_thicknesses = enumToOptions(PanelThickness, {
  labels: optionMap.panel_thickness
})
const optimizers = enumToOptions(Optimizers, {
  allow_none: true,
  labels: optionMap.optimizers
})
</script>

<template>
  <div class="section">
    <h2 class="benders-small-title is-uppercase">{{ $t('conditions.solar_cells') }}</h2>
    <div class="panel-measurements is-flex is-justify-content-center">
      <div class="panel-height mr-2">
        <label for="panel-height" class="benders-small-title">
          {{ $t('conditions.panel_height') }}
        </label>
        <FormattedNumber
          id="panel-height"
          class="is-relative"
          style="width: 100px"
          v-model="project.selections.panel.height"
          :format-options="{
            maximumSignificantDigits: 4,
            maximumFractionDigits: 0
          }"
          :asRaw="true"
          :error="errors.panel_height"
        >
          <span class="unit-overlay has-text-grey">mm</span>
        </FormattedNumber>
      </div>
      <div class="panel-width">
        <label for="panel-width" class="benders-small-title">
          {{ $t('conditions.panel_width') }}
        </label>
        <FormattedNumber
          id="panel-width"
          class="is-relative"
          style="width: 100px"
          v-model="project.selections.panel.width"
          :format-options="{
            maximumSignificantDigits: 4,
            maximumFractionDigits: 0
          }"
          :asRaw="true"
          :error="errors.panel_width"
        >
          <span class="unit-overlay has-text-grey">mm</span>
        </FormattedNumber>
      </div>
    </div>
    <div class="panel-thickness">
      <h2 class="benders-small-title">{{ $t('conditions.panel_thickness') }}</h2>
      <RadioSelector
        :items="panel_thicknesses"
        group="panel_thickness"
        v-model="project.selections.panel.thickness"
      />
    </div>
    <div class="optimizer">
      <h2 class="benders-small-title">
        {{ $t('conditions.optimizer_mount') }}
      </h2>
      <RadioSelector :items="optimizers" group="optimizer" v-model="project.selections.optimizer" />
    </div>
  </div>
</template>
