export enum Category {
  Takpannor,
  Tillbehör,
  Taksäkerhet,
  Tillval,
  Emballage
}

export enum TerrainType {
  None = '',
  '0' = '0',
  I = 'I',
  II = 'II',
  III = 'III',
  IV = 'IV'
}

export enum WindZone {
  None = '',
  '21' = '21',
  '22' = '22',
  '23' = '23',
  '24' = '24',
  '25' = '25',
  '26' = '26'
}

export enum SnowZone {
  None = '',
  '1.0' = '1.0',
  '1.5' = '1.5',
  '2.0' = '2.0',
  '2.5' = '2.5',
  '3.0' = '3.0',
  '3.5' = '3.5'
  // '4.5' = '4.5',
  // '5.5' = '5.5'
}

export enum GenericYesNo {
  None = '',
  Yes = 'yes',
  No = 'no'
}

export enum Optimizers {
  None = 'none',
  OnePerPanel = 'one-per-panel',
  OnePerTwoPanels = 'one-per-two-panels'
}

export enum PanelThickness {
  None = '',
  '30' = 30,
  '35' = 35
}

export enum RoofType {
  None = '',
  Sadel = 'sadel',
  Pulpet = 'pulpet'
}

export enum LathDimension {
  None = '',
  '25x48' = '25x48',
  '45x70' = '45x70'
}

export enum TileFamily {
  None = '',
  Palema = 'palema'
}

export enum TileFinish {
  None = '',
  Benderit = 'benderit',
  Candor = 'candor'
}

export enum TileColor {
  None = '',
  Svart = 'svart',
  Tegelröd = 'tegelröd'
}
