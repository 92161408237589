import type { RadioOption } from '@/project.d'
import {
  Category,
  SnowZone,
  Optimizers,
  GenericYesNo,
  TileFamily,
  TileFinish,
  TileColor,
  RoofType,
  LathDimension,
  PanelThickness
} from '@/options.d'

import { storeToRefs } from 'pinia'
import { useProjectStore } from '@/store/project'
import pinia from '@/store/store'
import { minTakbredd, minTakfall } from '@/calculation/common'
import { collectArticles } from '@/calculation/articles'

import { i18n } from '@/i18n'
const { t } = i18n.global

const project = useProjectStore(pinia)
// Methods etc, non reactive
const { getRoofById, setWeight } = project

// Refs, reactive
const {
  selections,
  step,
  roofIndex,
  activeRoof,
  roofCount,
  calculated,
  projectName,
  computedArticles,
  conditionsValid,
  selectionErrors
} = storeToRefs(project)

export {
  project,
  selections,
  step,
  roofIndex,
  activeRoof,
  roofCount,
  calculated,
  projectName,
  getRoofById,
  setWeight,
  computedArticles,
  conditionsValid,
  selectionErrors,
  // Calculations
  minTakbredd,
  minTakfall,
  collectArticles
}

const enumKeys = (enumObject: any) => {
  return Object.keys(enumObject) // only works for enums with strings
}

export const enumToOptions = (
  enumObject: any,
  options: { allow_none?: boolean; labels?: { [key: string]: string } } = {
    allow_none: false,
    labels: {}
  }
): RadioOption[] => {
  return enumKeys(enumObject).reduce((acc, key) => {
    if (!options.allow_none && key === 'None') {
      return acc
    }
    // console.log('key', key, options.labels?.[enumObject[key]])
    return [
      ...acc,
      {
        label: options.labels?.[enumObject[key]] || key,
        value: enumObject[key]
      }
    ]
  }, [] as RadioOption[])
}

export function markInputValue(e: HTMLInputElement | MouseEvent) {
  if (e instanceof HTMLInputElement) {
    e.select()
  } else {
    const target = e.target as HTMLInputElement
    target.select()
  }
}

export function formatNumber(value: number, minimumFractionDigits = 0, maximumFractionDigits = 2) {
  // if decimal value, always show maximumFractionDigits
  if (value % 1 !== 0) {
    minimumFractionDigits = maximumFractionDigits
  }

  return Intl.NumberFormat('sv-SE', {
    minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits + 1 // Add one to maximumFractionDigits to make sure we don't round up
  })
    .formatToParts(value)
    .map((part) => {
      if (part.type === 'fraction' && part.value.length > maximumFractionDigits) {
        return part.value.slice(0, -1) // Remove the extra digit we added to maximumFractionDigits
      }
      return part.value
    })
    .join('')
}

export function weightFormatter(v: number) {
  const weight = new Intl.NumberFormat('sv-SE', {
    unit: 'kilogram',
    maximumFractionDigits: 2
  })
  let val = weight.format(v)
  val = val.replace(/(,[0-9])$/, '$10') // Pad to two zeros
  return val.replace('−', '-') // Replace big minus with normal dash
}

const categoryMap: { [key in Category]: string } = {
  [Category.Takpannor]: 'Takpannor',
  [Category.Tillbehör]: 'Tillbehör',
  [Category.Taksäkerhet]: 'Taksäkerhet',
  [Category.Tillval]: 'Tillval',
  [Category.Emballage]: 'Emballage'
}

const optionMap: {
  optimizers: { [key in Optimizers]: string }
  snow_zone: { [key in SnowZone]: string }
  genericyesno: { [key in GenericYesNo]: string }
  roof_type: { [key in RoofType]: string }
  tile_family: { [key in TileFamily]: string }
  tile_finish: { [key in TileFinish]: string }
  tile_color: { [key in TileColor]: string }
  lath_dimension: { [key in LathDimension]: string }
  panel_thickness: { [key in PanelThickness]: string }
} = {
  optimizers: {
    [Optimizers.None]: t('options.optimizers.none'),
    [Optimizers.OnePerPanel]: t('options.optimizers.one_per_panel'),
    [Optimizers.OnePerTwoPanels]: t('options.optimizers.one_per_two_panels')
  },
  snow_zone: {
    [SnowZone.None]: '',
    [SnowZone['1.0']]: '1',
    [SnowZone['1.5']]: '1.5',
    [SnowZone['2.0']]: '2',
    [SnowZone['2.5']]: '2.5',
    [SnowZone['3.0']]: '3',
    [SnowZone['3.5']]: '3.5'
    // [SnowZone['4.5']]: '4.5',
    // [SnowZone['5.5']]: '5.5'
  },
  genericyesno: {
    [GenericYesNo.None]: '',
    [GenericYesNo.Yes]: t('generic.yes'),
    [GenericYesNo.No]: t('generic.no')
  },
  roof_type: {
    [RoofType.None]: '',
    [RoofType.Pulpet]: t('options.roof_type.pulpet'),
    [RoofType.Sadel]: t('options.roof_type.sadel')
  },
  tile_family: {
    [TileFamily.None]: '',
    [TileFamily.Palema]: t('options.tile_family.palema')
  },
  tile_finish: {
    [TileFamily.None]: '',
    [TileFinish.Benderit]: t('options.tile_finish.benderit'),
    [TileFinish.Candor]: t('options.tile_finish.candor')
  },
  tile_color: {
    [TileFamily.None]: '',
    [TileColor.Svart]: t('options.tile_color.svart'),
    [TileColor.Tegelröd]: t('options.tile_color.tegelrod')
  },
  lath_dimension: {
    [LathDimension.None]: '',
    [LathDimension['25x48']]: t('options.lath_dimension.25x48'),
    [LathDimension['45x70']]: t('options.lath_dimension.45x70')
  },
  panel_thickness: {
    [PanelThickness.None]: '',
    [PanelThickness['30']]: t('options.panel_thickness.30'),
    [PanelThickness['35']]: t('options.panel_thickness.35')
  }
}

export { categoryMap, optionMap }
