<script setup lang="ts">
import { computed } from 'vue'
import { step } from '@/helpers'

const clickHandler = (i: number) => {
  if (prop.active != i && prop.editing) return
  if (prop.editing && !prop.checked?.[i]) return
  emit('change', i)
}

const filteredItems = computed(() => {
  return prop.items.filter(Boolean)
})

const prop = defineProps<{
  active: number
  items: {
    name: string
  }[]
  editing?: boolean
  checked?: boolean[]
  cancel?: boolean
  max?: number
}>()

const emit = defineEmits<{
  (e: 'new'): void
  (e: 'reset'): void
  (e: 'cancel'): void
  (e: 'change', index: number): void
}>()
</script>

<template>
  <div class="item-display">
    <button
      v-for="(roof, i) in filteredItems"
      :key="roof.name + i"
      @click="clickHandler(i)"
      class="item-display-name is-uppercase"
      :class="{
        active: active == i,
        disabled: editing && (!checked || !checked[i] || active != i)
      }"
    >
      <span v-if="checked && checked[i]" class="icon">
        <font-awesome-icon icon="check" />
      </span>
      <span class="roof-name">{{ roof.name }}</span>
    </button>
    <template v-if="step === 2">
      <button v-if="cancel" class="item-display-name is-flex-shrink-0" @click="emit('cancel')">
        {{ $t('generic.cancel') }}
      </button>
      <!-- <button v-else-if="editing" @click="emit('reset')" class="reset">
        {{ $t('generic.reset') }}
      </button> -->
      <button
        v-else-if="!editing && (!max || items.length < max)"
        @click="emit('new')"
        class="add is-flex-shrink-0"
      >
        {{ $t('generic.add') }}
      </button>
    </template>
  </div>
</template>

<style lang="sass" scoped>
@import "@/assets/variables.sass"

.item-display // items in either roofs or extensions, their "name headers"
  margin-top: 1.5rem
  display: flex
  justify-content: center
  &-name
    position: relative
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    bottom: 0.7rem
    padding: 0 0.5rem 0.85rem
    // text-transform: uppercase
    margin-right: 0.5rem
    letter-spacing: 0.05rem
    color: $gray-10
    &.active
      border-bottom: 2px solid $benders-red
      color: $benders-red

  .icon
    color: #008000
    vertical-align: bottom
    font-size: 0.875rem
  .reset, .add
    position: relative
    bottom: 0.7rem
    padding: 0 0.5rem 0.85rem
  button
    border: 0
    background: unset
    font-family: $family-primary
    font-size: 0.95rem
    margin-bottom: -0.83rem
    &:hover
      color: $benders-red
    &.add
      color: $benders-red
      letter-spacing: 0.02rem
      &:hover
        color: $black
    &.disabled
      color: $gray-5
      cursor: default
</style>
