<script setup lang="ts">
import type { Roof } from '@/project.d'
import { ref, watch } from 'vue'
import { useProjectStore } from '@/store/project'

const { getRoofById } = useProjectStore()

const roof = ref<Roof>()

watch(
  () => prop.id,
  (id) => {
    roof.value = getRoofById(id) ?? undefined
  },
  { immediate: true }
)

const prop = defineProps<{
  id: string
}>()
</script>

<template>
  <div class="is-flex is-flex-direction-column">
    <span class="has-text-right"><slot></slot></span>
  </div>
</template>
