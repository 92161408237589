<template>
  <div class="check icon-option-check">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path
        d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"
        fill="currentColor"
      ></path>
    </svg>
  </div>
</template>

<style lang="sass" scoped>
@import "@/assets/variables.sass"

.check
  display: flex
  justify-content: center
  align-items: center
  color: white
  background: $benders-red
  position: absolute
  top: 0
  right: 0
  padding: 6px
  opacity: 1
  z-index: 10
  svg
    width: 11px
    height: 11px
</style>
